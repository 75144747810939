var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DinamicallyForm',{ref:"Formulario",attrs:{"FieldsForView":_vm.FieldsForRow,"FormReadOnly":_vm.FormReadOnly,"BotonGuardar":_vm.BotonGuardar,"BotonCancelar":_vm.BotonCancelar,"BotonClonar":_vm.BotonClonar,"AutoSaveData":_vm.AutoSaveData,"textos_tabs":_vm.textos_tabs},on:{"update:FieldsForView":function($event){_vm.FieldsForRow=$event},"update:fields-for-view":function($event){_vm.FieldsForRow=$event},"EventGuardar":function($event){return _vm.HandlerSave($event)},"EventCancelar":function($event){return _vm.Cancelar()},"EventClonar":function($event){return _vm.Clonar($event)},"EventChange":function($event){return _vm.EventChange($event)},"EventDontSave":function($event){return _vm.DontSave()}},scopedSlots:_vm._u([{key:"hist_contratos_empleado",fn:function(ref){
var field = ref.field;
return [_c('FullDynamicTable',{attrs:{"id_tabla":"24","EventMethod":function () {
            return field.data;
          },"EventMethodNew":function (id) {
            _vm.open_form_contrato(id);
          },"EventMethodUpdate":function (obj) {
            _vm.open_form_contrato(obj.id);
          }},on:{"refresh":function($event){return _vm.refresh_hist_contratos()}}})]}},{key:"fichajes_empleados",fn:function(ref){
          var field = ref.field;
return [_c('FullDynamicTable',{attrs:{"id_tabla":"26","EventMethod":function () {
            return field.data;
          }},on:{"refresh":function($event){return _vm.refresh_fichajes_empleado()}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }